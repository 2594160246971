import React from "react";
import { Link } from "react-router-dom";


const Logo = () => {
    function set(path) {
        console.log('set', path)
        // document.getElementsByClassName("about")[0].style.visibility="visible";
        for (let el of document.querySelectorAll('.home')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.about')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.services')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.partnering')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.news')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.careers')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.resources')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.news')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.contact')) el.style.visibility = 'hidden';
        for (let el of document.querySelectorAll('.' + path)) el.style.visibility = 'visible';
    }
    return (
        <div className="toplogo">
            <Link to='/'>
            <img src="/toplogo.png" alt="vast scientific" onClick={() => set('home')}></img>
            </Link>
        </div>
    )

}

export default Logo;