import React from "react";
import Navigation from "./navigation";
import Logo from "./logo";
import Foot from "./foot";


const Contact = (arg) => {
    let contenttop =
        <div className="content-top contact">
            <p>Vast Scientific is a professional services organization focused on the computational, analytical and
                data storage needs of our science based clients. Where relevant to our client's success, we encourage
                and leverage relationships with partners who can help us deliver a competitive advantage for our
                clients. If you are interest in exploring a potential partnership with VAST Scientific, contact us: </p>
            <p><a href="mailto:INFO@VASTScientific.com">INFO@VASTScientific.com</a></p>
        </div>

    let contentbot = 
        <div className="content-bot home"></div>

return (
    <div className="main">
        <Logo />
        <Navigation />
        {contenttop}
        {contentbot}
        <Foot />
    </div>
);

}

export default Contact;