import React from "react";
import Navigation from "./navigation";
import Logo from "./logo";
import Foot from "./foot";

const About = (arg) => {
    console.log("in about")

    let contenttop =
        <div className="content-top about" style={{ 'visibility': 'visible' }}>
            <p>VAST Scientific principals and staff are the basis of our business. Confident and enthusiastic
                leadership are the cornerstones to collaborative success with our customers and partners. Our
                professional staff consists of Ph.D. level scientists with expertise in data science,
                bioinformatics, information architecture, and other related fields. We have tested experience and
                extensive knowledge in developing, delivering and optimizing software applications.</p>
            <p></p>

        </div>
    let contentbot =
        <div className="content-bot about">
            <h2>Leadership</h2>
            <h2 className="sectitle">Michael Athanas, Ph.D. <i>Director</i> </h2>
            <p>Athanas&rsquo; unique application of the intersection of his skills and experience as a project manager,
                scientist, data
                analyst, software architect, developer, IT architect, and project &amp; team manager has provided
                measurable benefit to many leading life science companies. At VAST, Athanas builds and extends his
                success as founding partner and President of The BioTeam at building a highly reputable, responsive, and
                professional team of industry experts.
            </p>
            <p>
                As <i>Director of Research and Development</i> at <a target="_blank" rel="noopener noreferrer"
                    href="https://www.thermofisher.com/us/en/home.html">ThermoFisher</a>,
                Athanas led a group of scientists and developers exploring
                innovations for accelerating reproducibility and contextualization of scientific research. The <i>skunk
                    works</i>
                research team
                collaborated with customers and developed rapid proof-of-concept solutions to guide internal
                product development strategy and direction. Projects include:
            </p>
            <ul>
                <li><i>Laboratory Ontology</i> - As instrumentation representative leader for the <a target="_blank"
                    rel="noopener noreferrer" href="https://allotrope.org">Allotrope Foundation</a>,
                    Athanas developed cross-industry ontologies based upon open <a target="_blank" rel="noopener noreferrer"
                        href="https://www.w3.org/standards/semanticweb/">
                        W3C Semantic Web standards</a> and industry best practices.</li>
                <li><i>Semantic IoT</i> - Demonstrate autonomous scientific instruments that orchestrate, optimize and
                    self-monitor a
                    laboratory environment.</li>
                <li><i>Mneme Project</i> - Realtime research information aggregation to deliver <i>predictive</i> and
                    <i>proactive</i>
                    contextual analytics to bridge information silos.
                </li>
            </ul>

            <p>Athanas has an extended history of bringing successful software. Most notably,
                <a target="_blank" rel="noopener noreferrer" href="https://www.thermofisher.com/us/en/home.html">ThermoFisher
                    Scientific&rsquo;s </a>
                <i>SIEVE</i> platform, a powerful label-free mass spectrometry analytics software for biomarker discovery and
                conformation. In addition, Athanas made several analytical tools freely available to the scientific community
                including <a href="/rawmeat">RawMeat</a> and <a href="/rocstation">ROC Station</a>.
                At BioTeam, Athanas developed the scalable genomics analysis platform <i>iNquiry</i> which received 1st place
                Software Design Award from <a target="_blank" rel="noopener noreferrer" href="https://apple.com">Apple</a>.
            </p>
            <p>
                Prior to the
                BioTeam, Athanas was Director of Scientific Consulting at Blackstone Computing, Athanas led a highly
                reputable team of scientists and developers focused upon enabling large-scale vendor neutral scientific
                computing solutions. He is the inventor (patent pending) of unique scalable data balancing algorithms
                that have provided core IP to Blackstone software products. As a Bioinformaticist at Cereon Genomics he
                collaborated in the transcriptional profiling team as well as created software infrastructures for
                high-throughput analysis including a scalable workflow engine based upon mobile agent technologies.
                Previous to Cereon, Athanas was a Research Scientist at Cornell University&rsquo;s Wilson Synchrotron
                Laboratory exploring four-quark bound states produced in photon-photon interactions. At Cornell, he was
                Co-Architect of the Nile Project, a National Science Foundation project addressing large-scale databases
                and distributed computing in experimental elementary particle physics. Athanas received his Doctorate in
                Physics at the Mellon College of Science at Carnegie Mellon in Pittsburgh.
            </p>
            <p>Michael is an avid <a href='https://together.yoga'>Yoga practitioner and Instructor </a> 
             and can easily be found teaching in Silicon Valley.</p>

            <b>PUBLICATIONS </b>
            <ul>
                <li>278 Publications</li>
                <li>4000+ Citations</li>
                <li><a target="_blank" rel="noopener noreferrer"
                    href="https://www.researchgate.net/profile/Michael_Athanas">ResearchGate</a>
                </li>
            </ul>


            <b>INTELLECTUAL PROPERTY:</b>
            <ul>
                <li>Microbial Classification of Biological Sample by Mass Spectrometry - <a target="_blank"
                    rel="noopener noreferrer" href="https://www.patentguru.com/US2020234787A1">US2020234787A1</a></li>
                <li>3D Mass Spectrometry Predictive Classification - <a target="_blank" rel="noopener noreferrer"
                    href="https://patentguru.com/EP3584795A3">EP3584795A3</a></li>
                <li>Decomposing Tandem Mass Spectra Generated by All-Ions - <a target="_blank" rel="noopener noreferrer"
                    href="https://www.patentguru.com/EP2775509B1">EP2775509B1</a></li>
                <li>Use of mass spectral difference networks for determining charge state - <a target="_blank"
                    rel="noopener noreferrer" href="https://www.patentguru.com/US9159538B1">US9159538B1</a></li>
                <li>Methods and apparatus for decomposing tandem mass spectra generated by all-ions fragmentation - <a
                    target="_blank" rel="noopener noreferrer" href="https://www.patentguru.com/EP2775509B1">EP2775509B1</a>
                </li>

                <li>Use of mass spectral difference networks for determining charge state, adduction, neutral loss and
                    polymerization - <a target="_blank" rel="noopener noreferrer"
                        href="https://www.patentguru.com/US9299547B2">US9299547B2</a></li>
                <li>Assays for Differentiating between Ischemic and Hemorrhagic Stroke in a Human Patient - <a target="_blank"
                    rel="noopener noreferrer" href="https://www.patentguru.com/US2012064558A1">US2012064558A1</a></li>
                <li>Methods for Predicting Trisomy 21 in a Fetus - <a target="_blank" rel="noopener noreferrer"
                    href="https://www.patentguru.com/US2010216250A1">US2010216250A1</a></li>
            </ul>


            <h2>Advisory Board</h2>

            <h2 className="sectitle">Peter Athanas, Ph.D.</h2>
            <p>Peter Athanas is a CMC research scientist and an emeritus professor in the
                Bradley Department of Electrical and Computer Engineering at Virginia Tech. Peter
                directs the Configurable Computing Laboratory. His laboratory participates in diverse
                research projects related to reconfigurable computing, software radios, networking, and
                scientific computing. His research sponsors include NRO, NSA, DARPA, IARPA, ONR,
                AFRL, MDA, OSD and LTS. He is the author of over 130 publications and five patents.
                Peter received his BS degree in electrical engineering from the University of
                Toledo, his MS degree in electrical engineering from Rensselaer Polytechnic Institute,
                his Sc.M. degree in applied mathematics at Brown University, and his Ph.D. degree in
                electrical engineering from Brown University. Peter&#39;s complete CV is available <a
                    href="https://docs.google.com/document/d/1xgGY3fB7MX7_A81rAQxax9cs6DgQteneq0HRD8p5RvA/edit">here</a>.
            </p>

            <h2 className="sectitle">Lisa Durbeck, Ph.D.</h2>
            <p>Dr. Lisa Durbeck is a CMC director and a former research scientist and program
                manager for the federal government, where she managed a large portfolio of AI/ML
                research aimed at automating parts of GEOINT imagery analysis. She is currently a
                Use of or disclosure of proposal data is subject to the restriction on the title page of this document. PAGE
                15
                DNI-funded postdoctoral fellow at Virginia Tech, where her research, in collaboration
                with Peter, is focused on work directly related to this topic, namely, efficient and
                accurate community detection in complex graphs. This has given the CMC Team two
                years of R&D into the research problem area of this SBIR topic. Lisa is the author of
                over 30 publications and was awarded a US patent in computer architecture.
                Lisa received her BA in biology from Bryn Mawr College, as well as a BS
                equivalent in Computer Science from the University of California, Berkeley; her MS in
                Computer Science from the University of Utah; and her Ph.D. in Computer Engineering
                from Virginia Tech. </p>

        </div>


    return (
        <div className="main">
            <Logo />
            <Navigation />
            {contenttop}
            {contentbot}
            <Foot />
        </div>
    );



}

export default About;