import React from "react";
import Navigation from "./navigation";
import Logo from "./logo";
import Foot from "./foot";


const News = (arg) => {
    let contenttop =
        <div className="content-top news">
            <div className="phone">
                <div className="articletitle">
                    <a href="https://pubs.rsc.org/en/Content/ArticleLanding/2012/AY/C1AY05473B">Evaluation
                        of label-free quantitative proteomics in a plant matrix: A
                        case study of the night-to-day transition in corn leaf</a>
                </div>
                <div className="articleauthor">
                    Leah S. Riter, Pamela K. Jensen, Joan M. Ballam, Ewa
                    Urbanczyk-Wochniak, Timothy Clough, Olga Vitek, Jennifer Sutton,
                    Michael Athanas, Mary F. Lopez and Susan MacIsaac
                </div>
                <div className="articlelink">
                    <a href="https://pubs.rsc.org/en/journals/journalissues/ay/">Anal.
                        Methods, 2012, Advance Article</a>
                </div>
            </div>
        </div>


    let contentbot =
            <div className="content-bot news">


                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <a href="ASMS11_T103_MATHANAS.pdf">Disulfide mapping validation
                                using label-free differential analysis</a>
                        </div>
                        <div className="articleauthor">
                            Michael Athanas1, S Peterman2, D Sarracino2, B Krastins2, A
                            Prakash2, T Rezai2, M F Lopez2
                        </div>
                        <div className="articlelink">
                            <a href="https://www.asms.org/">ASMS 2011</a>
                        </div>
                    </div>
                </div>


                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <a href="Athanas_2S_MSACL_Poster_v03.pdf">Disulfide mapping
                                validation using label-free differential analysis</a>
                        </div>
                        <div className="articleauthor">
                            Michael Athanas1, S Peterman2, D Sarracino2, B Krastins2, A
                            Prakash2, T Rezai2, M F Lopez2
                            <p>1VAST Scientific; 2Thermo Fisher Scientific BRIMS
                                Center, ; <br /> Novato, CA</p>
                        </div>
                        <div className="articlelink">
                            <a href="https://www.msacl.org/2012_e-programs/MSACL_2012_Short_e-Program.pdf">MSACL 2011 Conference Poster</a>
                        </div>
                    </div>
                </div>


                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <a href="Athanas_TwoPass_MSACL_Poster_v01.pdf">Detection of
                                Age-Related PTMs as m/z Pair Tags in LC-MS Measurement of
                                Mesenchymal Stem Cell Lysates using a Two-Pass Sample
                                Acquistion</a>
                        </div>
                        <div className="articleauthor">
                            Michael Athanas1, D Sarracino2, B Krastins2, A Prakash2, T
                            Rezai2, J Sutton2, V Lunyak3, M Dobke3, M F Lopez2
                            <p>1VAST Scientific; 2Thermo Fisher Scientific BRIMS
                                Center, ; 3Buck Institute for Age Research,
                                Novato, CA</p>
                        </div>
                        <div className="articlelink">
                            <a href="https://www.msacl.org/">MSACL 2011 Conference Poster</a>
                        </div>
                    </div>
                </div>

                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <a
                                href="/news/HUPO2010_Athanas_Detection_of_Age-Related_PTMs_MA.pdf">Detection
                                of Age-Related PTMs as m/z Pair Tags in LC-MS Measurement of
                                Mesenchymal Stem Cell Lysates</a>
                        </div>
                        <div className="articleauthor">
                            <p>Michael Athanas1, D Sarracino2, B Krastins2, A Prakash2, T
                                Rezai2, J Sutton2, V Lunyak3, M Dobke3, M F Lopez2<br /></p>
                            <p>1VAST Scientific; 2Thermo Fisher Scientific BRIMS
                                Center, ; 3Buck Institute for Age Research,<br />
                                Novato, CA</p>
                        </div>
                        <div className="articlelink">
                            <a href="https://www.hupo2010.com/">HUPO 2010 Conference
                                Poster</a>
                        </div>
                    </div>
                </div>

                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <a
                                href="HUPO 2010_Athanas_Discovery of Neurovascular Mediators_Final_lg.pdf">Discovery
                                of Neurovascular Mediators in PFO-Related Stroke Using a
                                Two-Pass<br /> Label-Free Workflow</a>
                        </div>
                        <div className="articleauthor">
                            <p>Michael Athanas1; David Sarracino2; Taha Rezai2; Amol Prakash2;
                                Jennifer Sutton2; Bryan Krastins2; Mingming Ning3; Mary F Lopez2
                            </p>
                            <p>1VAST Scientific; <br /> 3ThermoFisher Scientific,
                                BRIMS, ; <br /> 3Mass General Hospital / Harvard
                                Medical School, Boston, MA</p>
                        </div>
                        <div className="articlelink">
                            <a href="https://www.hupo2010.com/">HUPO 2010 Conference
                                Poster</a>
                        </div>
                    </div>
                </div>

                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <a href="https://pubs.acs.org/doi/abs/10.1021/pr100153j">Mass
                                Spectrometric Discovery and Selective Reaction Monitoring
                                (SRM) of Putative Protein Biomarker Candidates in First
                                Trimester Trisomy 21 Maternal Serum</a>
                            <p><a href="https://pubs.acs.org/doi/abs/10.1021/pr100153j?https://pubs.acs.org/doi/abs/10.1021/pr100153j">link</a>
                            </p>
                            <a
                                href="https://pubs.acs.org/doi/abs/10.1021/pr100153j?https://pubs.acs.org/doi/abs/10.1021/pr100153j"><img
                                    src="brims.jpg" alt="SIEVE+Pinpoint Workflow"
                                    width="297" height="213" /></a>
                        </div>
                        <div className="articleauthor">
                            <a href="https://pubs.acs.org/doi/abs/10.1021/pr100153j?https://pubs.acs.org/doi/abs/10.1021/pr100153j" >
                                <p>Mary F Lopez*&dagger;, Ramesh Kuppusamy&Dagger;, David A
                                    Sarracino&dagger;, Amol Prakash&dagger;, Michael
                                    Athanas&sect;, Bryan Krastins&dagger;, Taha Rezai&dagger;,
                                    Jennifer N Sutton&dagger;, Scott Peterman&dagger; and Kypros
                                    Nicolaides&Dagger; ThermoFisher Scientific BRIMS, 790
                                    Memorial Drive, ssachusetts 02139, VAST
                                    Scientific, 790 Memorial Drive, ssachusetts
                                    02139, and Fetal Medicine Foundation, 137 Harley Street,
                                    London, United Kingdom W1G 6BG
                                </p>
                            </a>
                        </div>
                        <div className="articlelink">
                            <a
                                href="https://pubs.acs.org/doi/abs/10.1021/pr100153j?https://pubs.acs.org/doi/abs/10.1021/pr100153j">link</a><a
                                    href="https://asms.org">Journal of Proteome Research; May
                                25,
                                2010 (Article) DOI: 10.1021/pr100153j</a>
                        </div>
                    </div>
                </div>


                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a href="ASMS10_Th227_MAthanas_Final_hr.pdf">A two-pass
                                informatics-driven label-free workflow for discovery of
                                neurovascular mediators in pfo-related stroke</a></p>
                        </div>
                        <div className="articleauthor">
                            <p>Michael Athanas1; David Sarracino2; Taha Rezai2; Amol Prakash2;
                                Jennifer Sutton2; Bryan Krastins2; Mingming Ning3; Mary F Lopez2
                            </p>
                            <p>1VAST Scientific; <br /> 3ThermoFisher Scientific,
                                BRIMS, ; <br /> 3Mass General Hospital / Harvard
                                Medical School, Boston, MA</p>
                        </div>
                        <div className="articlelink">
                            <p><a href="https:asms.org">ASMS</a> 2010 Conference Poster</p>
                        </div>
                    </div>
                </div>

                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a href="HUPO_2009_Poster_ATHANAS_MultiMarkerPanel_TC519_V4.pdf">Discovery
                                analysis of multiple protein marker panels: Optimizing
                                sensitivity and selectivity</a><a
                                    href="US_HUPO_2009_TB_poster.pdf" target="_blank">link</a></p>
                        </div>
                        <div className="articleauthor">
                            <p>M. Athanas1, A, Prakash2, T. Rezai2, B. Krastins2, D. Sarracino2,
                                Kypros Nicolaides3, Ramesh Kuppusamy3, Mary F. Lopez2
                                1VAST Scientific, USA, 2Thermo Fisher Scientific,
                                BRIMS, , USA, 3Fetal Medicine Foundation, London,
                                UK</p>
                            <p>1VAST Scientific; 2University of Washington,
                                Seattle, WA; 3ThermoFisher Scientific, ;
                                4ThermoFisher Scientific, Somerset, NJ; 5Harvard University,
                                Boston, MA</p>
                        </div>
                        <div className="articlelink">
                            <p><a href="msacl.org">Mass Spectrometry Application to the Clinical
                                Lab</a>
                                <a href="https://www.hupo.org/">2010 Conference Poster</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a href="HUPO_2009_Poster_ATHANAS_MultiMarkerPanel_TC519_V4.pdf">Analysis
                                of multiple protein marker panels: Optimizing sensitivity
                                and selectivity</a><a href="US_HUPO_2009_TB_poster.pdf"
                                    target="_blank">link</a></p>
                        </div>
                        <div className="articleauthor">
                            <p>M. Athanas1, A, Prakash2, T. Rezai2, B. Krastins2, D. Sarracino2,
                                Kypros Nicolaides3, Ramesh Kuppusamy3, Mary F. Lopez2
                                1VAST Scientific, USA, 2Thermo Fisher Scientific,
                                BRIMS, , USA, 3Fetal Medicine Foundation, London,
                                UK</p>
                            <p>1VAST Scientific; 2University of Washington,
                                Seattle, WA; 3ThermoFisher Scientific, ;
                                4ThermoFisher Scientific, Somerset, NJ; 5Harvard University,
                                Boston, MA</p>
                        </div>
                        <div className="articlelink">
                            <p><a href="https://www.hupo.org/">International HUPO</a> 2009
                                Conference Poster</p>
                        </div>
                    </div>
                </div>
                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a href="ASMS09_M048_MAthanas_HR.pdf">Label Free Differential
                                Analysis: An Iterative Approach to Increased Coverage,
                                Improved Statistics and Results</a></p>
                        </div>
                        <div className="articleauthor">
                            <p>Michael Athanas1; Michael J. Maccoss2; Amol Prakash3; Lukas
                                Kall2; Daniela Tomazela2; Brendan Maclean2; Taha Rezai3; Bryan
                                Krastins3; David Sarracino3; Scott Peterman4; Alejandra Garces5;
                                Sarah Fortune5; Mary F Lopez3</p>
                            <p>1VAST Scientific; 2University of Washington,
                                Seattle, WA; 3ThermoFisher Scientific, ;
                                4ThermoFisher Scientific, Somerset, NJ; 5Harvard University,
                                Boston, MA</p>
                        </div>
                        <div className="articlelink">
                            <p><a href="https://asms.org">ASMS</a> 2009 Conference Poster</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>


                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a
                                href="https://onlinelibrary.wiley.com/doi/10.1002/prca.200780057">Performance
                                characteristics of an FT MS-based workflow for label-free
                                differential MS analysis of human plasma: standards,
                                reproducibility, targeted feature investigation, and
                                application to a model of controlled myocardial
                                infarction</a></p>
                        </div>
                        <div className="articleauthor">
                            <p>Jennifer Sutton, Dr. , Tori Richmond, Xu Shi, Michael Athanas,
                                Celeste Ptak, Robert Gerszten, Leo Bonilla</p>
                        </div>
                        <div className="articlelink">
                            <p><a
                                href="https://www3.interscience.wiley.com/journal/119818637/abstract?CRETRY=1&amp;SRETRY=0">PROTEOMICS
                                - Clinical Applications<br /> Volume 2 Issue 6, Pages 862 -
                                88</a>1</p>
                        </div>
                    </div>
                </div>

                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a href="US_HUPO_2009_TB_poster.pdf" target="_blank">Application
                                of label-free quantitative differential analysis for
                                biomarker discovery in Tuberculosis mycobacterium</a><a
                                    href="https://www.ll.mit.edu/publications/journal/pdf/vol16_no2/16_2_02Pomianowski.pdf">link</a>
                            </p>
                        </div>
                        <div className="articleauthor">
                            <p>Michael Athanas1, Bryan Krastins2 , David Sarracino2 , Amol
                                Prakash2,&nbsp; Taha Rezai2, Mary F Lopez2, Alejandra Garces3,
                                Sarah Fortuane3</p>
                            <p>1VAST Scientific ,2Thermo Fisher Scientific,
                                ;&nbsp; 3 Harvard University, Boston, MA</p>
                        </div>
                        <div className="articlelink">
                            <p>HUPO 2009 Conference Poster</p>
                        </div>
                    </div>
                </div>
                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a
                                href="https://fscimage.fishersci.com/images/D00270~.pdf">Quantitative,
                                Top-Down Identification of Differentially Expressed,
                                Endogenous Peptides in Human Serum: A Robust, Sensitive and
                                Novel Discovery Workflow</a></p>
                        </div>
                        <div className="articleauthor">
                            <p>David A. Sarracino, Bryan Krastins, Michael Athanas, Amol
                                Prakash, Taha Rezai, Kypros Nicolaides and Mary F Lopez .</p>
                        </div>
                        <div className="articlelink">
                            <p>Poster: Human Proteome Organisation Conference - 2008</p>
                        </div>
                    </div>
                </div>

                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a
                                href="https://www.thermo.com/eThermo/CMA/PDFs/Various/File_2846.pdf">Development
                                of a Novel Label-Free Differential Analysis Workflow for
                                Protein Biomarker Discovery from Renal Cell Carcinoma Tissue
                                Interstitial Fluid</a></p>
                        </div>
                        <div className="articleauthor">
                            <p>J. N. Sutton, S. E. Abbatiello, B. L. Hood, M. Athanas, R. Dhir,
                                M. Lopez, T. Conrads</p>
                        </div>
                        <div className="articlelink">
                            <p>Poster: Human Proteome Organisation Conference - 2007</p>
                        </div>
                    </div>
                </div>


                <div className="row phone">
                    <div className="offset-lg-4 col-lg-7 offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <div className="articletitle">
                            <p><a
                                href="https://arxiv.org/PS_cache/arxiv/pdf/0705/0705.3311v2.pdf">Weak
                                decays of Lambda Helium 4 Hypernuclei</a></p>
                        </div>
                        <div className="articleauthor">
                            <p>J. D. Parker, M. J. Athanas, P. D. Barnes, S. Bart, B.
                                Bassalleck, R. Chrien, G. Diebold, G. B. Franklin, K. Johnston,
                                P. Pile, B. Quinn, F. Rozon, R. Sawafta, R. A. Schumacher, R.
                                Stearns, I. Sukaton, R. Sutter, J. J. Szymanski and V. J. Zeps
                            </p>
                        </div>
                        <div className="articlelink">
                            <p>arXiv:0705.3311 (May 2007)</p>
                        </div>
                    </div>
                </div>


            </div>

    return (
        <div className="main">
            <Logo />
            <Navigation />
            {contenttop}
            {contentbot}
            <Foot />
        </div>
    );

}

export default News;