import './App.css';

import About from './components/about';
import Service from './components/service';
import Careers from './components/careers';
import Partnering from './components/partnering';
import Contact from './components/contact';
import News from './components/news';
import Resources from './components/resources';
import Main from './components/main';
import { BrowserRouter , Routes, Route } from "react-router-dom";

const Echo = (p) => {
  console.log('echo ', p)
}
function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/x' element={<Echo />} />
        <Route exact path='/resources' element={<Resources />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/partnering' element={<Partnering />} />
        <Route exact path='/services' element={<Service />} />
        <Route exact path='/news' element={<News />} />
        <Route exact path='/careers' element={<Careers />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/' element={<Main />} />
        <Route exact path='/index.html' element={<Main />} />
        {/* // for backwards compatibility */}
        {/* <Route path='/resources/index.html' element={<Resources />} />
        <Route path='/contact/index.html' element={<Contact />} />
        <Route path='/partnering/index.html' element={<Partnering />} />
        <Route path='/services/index.html' element={<Service />} />
        <Route path='/news/index.html' element={<News />} />
        <Route path='/careers/index.html' element={<Careers />} />
        <Route path='/about/index.html' element={<About />} />
        <Route path='/index.html' element={<Main />} /> */}
      </Routes>
    </BrowserRouter>


  );
}

export default App;
