import React from "react";
import Navigation from "./navigation";
import Logo from "./logo";
import Foot from "./foot";


const Main = (arg) => {
    let contenttop =
        <div className="content-top home">
            <p>VAST Scientific is a multidisciplinary consultancy organization
                providing inventive technology-related services for scientists and applied
                statistics thought leaders through our network of highly specialized informatics
                specialists and computational scientists who are all rooted in both the
                commercial and academic scientific communities. Our rigorous solution-based
                approach employs proven methodologies and best practices around quality,
                confidentiality, and confidence in all phases of project management and
                execution. </p>
        </div>

    let contentbot =
            <div className="content-bot home">
                VAST's expertise is based upon:
                <ul>
                    <li>Deep scientific software application knowledge, including application
                        and infrastructure scalability.
                    </li>
                    <li>Scientific analysis, informatics project management
                        and implementation, with ongoing support.</li>
                    <li>Optimization of scalable hardware architecture infrastructure
                        deployment to match outcome requirements.</li>
                    <li>Independent technology assessment and validation.</li>
                </ul>

                <div className="offset-sm-1 col-sm-9 offset-md-3 col-lg-4 stuff">
                <span style={{fontSize: "18px", color: "#900", fontFamily: "Verdana, Geneva, sans-serif;"}}>highlights</span>
                    <ul>
                   <li className="highlightsContact">OntologyTools, a service to locate related terms in a collection of
                        ontologies, is now available to the
                        <a href="https://allotrope.org">Allotrope</a> community.
                    </li>
                   <li className="highlightsContact">Thank you for your SIEVE beta 1 feedback - beta 2 is now in the limited
                        wild.
                        Final release is imminent.</li>
                   <li className="highlightsContact">Michael will present an illustrative overview of the SIEVE 2.0 workflow
                        at
                        the <a href="https://www.thermoscientific.com/ecomm/servlet/eventsdetail?contentId=54075&storeId=11152">North
                            America Thermo User's meeting</a>.</li>
                   <li className="highlightsContact"><a href="/rawmeat">RawMeat 2.1 is now available</a>. Now supports <a
                        href="https://planetorbitrap.com/orbitrap_q-exactive.php">QExactive</a>. </li>
                   <li className="highlightsContact">Michael presented <em><a
                        href="/support/Athanas_AOAC2011_CaliforniaWineStudy110920.pdf">LC/MS
                        Analysis of California Wines</a></em>, an invited talk to the <a href="https://www.aoac.org">AOAC
                            International Meeting</a>.</li>
                   <li className="highlightsContact">Come see the SIEVE 2.0 Preview at <a
                        href="https://www.asms.org/Conferences/AnnualConference/GeneralInformation/tabid/127/Default.aspx">ASMS</a>.
                    </li>
                   <li className="highlightsContact">Michael will be presenting <a href="/news/index.html">two posters</a>
                        at the
                        upcoming Mass Spectrometry
                        Applications in the Clinical Lab (<a href="https://msacl.org">MSACL</a>) in San Diego.</li>
                   <li className="highlightsContact">A new Receiver Operation Characteristic (ROC) analysis and learning
                        tool is
                        now freely available to the community: <a href="/rocstation">ROC Station</a>.</li>
                    </ul>

                </div>

            </div>


return (
    <div className="main">
        <Logo />
        <Navigation />
        {contenttop}
        {contentbot}
        <Foot />
    </div>
);

}

export default Main;