import React from "react";
import Navigation from "./navigation";
import Logo from "./logo";
import Foot from "./foot";

const Careers = (arg) => {
    let contenttop =
        <div className="content-top careers">
            <p>VAST Scientific is a consulting collective delivering premium but practical
                informatics solutions for the life sciences. Our partners and staff comprise highly reputable members of
                this life science informatics community. VAST is seeking to augment its highly valued staff with
                creative and confident talent in the fields of informatics and software engineering. Please respond to <a
                    href="mailto:jobs@vastscientific.com?subject=employment opportunites at VAST">
                    jobs@vastscientific.com</a>.</p>
        </div>

    let contentbot =
        <div className="content-bot careers">
            <h2>Informatics Specialist</h2>
            <p> Informatics&nbsp;includes the science of information, the practice of information
                processing, and the engineering of information systems.&nbsp;&nbsp;In scientific high performance
                computing, a static definition of an informaticist is difficult to articulate without resorting to such
                generalities. &nbsp;Rather, we are seeking people who thrive at understanding contemporary informatics
                and strive to incorporate new ideas rather than re-invention. </p>
            <p>VAST Scientific is seeking to engage a junior and senior level informatics consultant
                to augment its scientific outreach. The successful hire will
                have many information systems skills and experience on hand, such as:</p>
            <p><b>Scalable software engineering</b> &#8211; The ability to analyze a set of algorithms
                or application, comprehend computational bottlenecks, and invent solutions to deploy in a scalable
                infrastructure such as a cluster or multi-processor environment. &nbsp;An understanding of cloud data
                manipulation, storage, and visualization technologies is a plus. </p>
            <p><b>Data management and analysis</b> &#8211;Expertise is desired in designing,
                engineering, distributing, and managing data informatics solutions for quickly expanding data sets.</p>
            <p><b>Statistical analysis</b> &#8211; Experience in visualizing and deducing core
                information as well as uncovering unexpected correlations in very large evolving data sets.</p>
            <p>VAST Scientific specializes in proteomics mass spectrometry informatics.
                A background in mass spectrometry related sciences is highly
                desirable. </p>
            <p>When responding to this posting, please include a) a brief description of a recent
                completed informatics project and your role in its design and implementation, b) your CV, c) contact
                information for two references.</p>
            <p>&nbsp;</p>
            <h2 >Software Engineer</h2>
            <p>The successful and fortunate hire will have the following the following
                characteristics:</p>
            <ul>
                <li>
                    <p>Experienced in object oriented development strategies. Encapsulation and abstraction are critical
                        characteristics necessary for producing multi-platform software. Language experience: C#, C++,
                        C.&nbsp; Platforms: Windows Server, Linux.</p>
                </li>
                <li>
                    <p>Resumes displaying experience in the life sciences will get special attention. A successful and
                        industry recognized track record is desirable.</p>
                </li>
                <li>
                    <p>Comfortable and fluent on Windows Server and Visual Studio.</p>
                </li>
                <li>
                    <p>The candidate must be focused, agile, articulate, reliable, and comfortable in working
                        independently in an ultra-low bureaucracy environment. In addition, the candidate must be
                        self-motivated to meet or exceed production deadlines.</p>
                </li>
                <li>
                    <p>The candidate must be clairvoyant and capable of deriving true requirements from vague statements
                        of work. High ESP scores are a plus.</p>
                </li>
                <li>
                    <p>VAST Scientific is continually exposed to new technologies and techniques. The new candidate must
                        be technologically dexterous and driven to be current with the state-of-the-science.</p>
                </li>
            </ul>
            <p>When responding to this posting, please include:</p>
            <ol>
                <li>
                    <p>A thought intriguing snippet of code that you developed including a brief description of what the
                        snippet is supposed to achieve.</p>
                </li>
                <li>
                    <p>A brief paragraph about a new software technology that you not know little-to-nothing about but
                        would like to learn about with a brief passage why this new technology may become relevant.</p>
                </li>
                <li>
                    <p>Contact information for two references.</p>
                </li>
            </ol>
        </div>

    return (
        <div className="main">
            <Logo />
            <Navigation />
            {contenttop}
            {contentbot}
            <Foot />
        </div>
    );

}

export default Careers;