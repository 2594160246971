import React from "react";
import Navigation from "./navigation";
import Logo from "./logo";
import Foot from "./foot";


const Partnering = (arg) => {
    let contenttop =
        <div className="content-top partnering">
            <p>VAST Scientific consulting relies upon trusted technology partners to provide it's customers optimal
                end-to-end solutions. For more information about partnering with VAST Scientific, <a
                    href="mailto:info@vastscientific.com">contact us</a>.</p>
        </div>

    let contentbot =
            <div className="content-bot partnering">

            </div>


return (
    <div className="main">
        <Logo />
        <Navigation />
        {contenttop}
        {contentbot}
        <Foot />
    </div>
);

}

export default Partnering;