import React from "react";
import Navigation from "./navigation";
import Logo from "./logo";
import Foot from "./foot";


const Resources = (arg) => {
    let contenttop =
        <div className="content-top resources">
            <div className="resource">
                <div className="offset-lg-3 col-lg-1  offset-sm-3 col-sm-3 offset-xs-3 col-xs-3" >
                    <img src="/SIEVEdimension1.3.jpg" className="img-fluid" height="60" alt="sieve" />
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-6">
                    <p>SIEVE&trade; analysis platform provides label-free
                        quantitative differential expression analysis of proteins and peptides from the comparison of
                        multiple LC/MS and GC/MS datasets. It is a statistically rigorous tool for analyzing data from
                        biomarker discovery experiments.</p>
                    <p>ThermoFisher Scientific has collaborated with VAST for the exclusive development of the software.
                        See the v1.2 SIEVE the <a href="63141_SIEVE_BR_121610.pdf">brochure</a>. </p>

                </div>
            </div>
        </div>

    let contentbot =
            <div className="content-bot resources">
                <div className="resource">
                    <div className="offset-lg-3 col-lg-1  offset-sm-3 col-sm-3 offset-xs-3 col-xs-3" >
                        <a href="/rawmeat/index.html"><img src="rawmeat_logo.gif" width="80" className="img-fluid" alt="rawmeat"
                            longdesc="/rawmeat/index.html" /></a>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xs-6">
                        <p><a href="/rawmeat">RawMeat</a> is a quick data quality assessment tool
                            for mass
                            spectrometrists using Thermo instrument raw files. Get almost instant access to charge
                            distributions, spray current, target fill times, and other useful statistics. RawMeat is freely
                            available <a href="/rawmeat">here</a>.</p>

                    </div>
                </div>
                <div className="resource">
                    <div className="offset-lg-3 col-lg-1  offset-sm-3 col-sm-3 offset-xs-3 col-xs-3" >
                        <a href="/rocstation"><img src="/rocstation_screenshot.JPG" height="60" className="img-fluid" alt="rocstation" /></a>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-xs-6">
                        <p><a href="/rocstation">ROC Station</a> is a simple tool that can be used
                            to
                            learn about Receiver Operation Characteristic plots as well as process your data. A ROC analysis
                            is appropriate for determining the component that best differentiates two classes in a
                            multi-subject experiment. </p>
                    </div>
                </div>




                <div className="row phone">
                    <div className="offset-lg-4 col-lg-5  offset-sm-3 col-sm-8 offset-xs-3 col-xs-8">
                        <h1 className="sectitle">Documents and Tutorials</h1>
                    </div>
                </div>
                <div className="row phone">
                    <div className="offset-lg-4 col-lg-4  offset-sm-3 col-sm-8 offset-xs-3 col-xs-8" >

                        <p><a href="About ROCs.pdf">About ROCs</a></p>
                        <p>An overview of Reciever Operator Curves, comparing them to descriptive ratio statistics,
                            and explaining how they can be used in mass spec discovery and confirmation measurements.
                        </p>
                    </div>
                </div> <div className="row phone">
                    <div className="offset-lg-4 col-lg-4  offset-sm-3 col-sm-8 offset-xs-3 col-xs-8" >

                        <p><a href="Label-Free_Discovery_With_SIEVE_V1.3.pdf">SIEVE v1.3 Overview</a></p>
                        <p>A training tutorial about using SIEVE including chromatographic alignment, feature
                            detection, and feature identification for proteomics and small molecule experiments.
                            Presentation given at BRIMS workshop in February 2010.</p>
                    </div>
                </div>
            </div>
    return (
        <div className="main">
            <Logo />
            <Navigation />
            {contenttop}
            {contentbot}
            <Foot />
        </div>
    );

}

export default Resources;