import React from "react";

const Foot = () => {
    var year = new Date().getFullYear();
    return(
        <div className="foot">
          &copy; {year} Vast Scientific, Inc.
        </div>
    )
}

export default Foot;