
import React from "react";
// import HoverImage from "react-hover-image";
import { Link } from "react-router-dom";

const Navigation = () => {


    return (
        <div className="navigation" >
            <Link to='/about' className="about">
                <img src={'/but_about_off.gif'} alt="about" />
                <img src={'/but_about_on.gif'} alt="about" />
            </Link>
            <Link to="/services" className="services">
                <img src={'/but_services_off.gif'} alt="services" />
                <img src={'/but_services_on.gif'} alt="services" />
            </Link>
            <Link to='/partnering' className="partnering">
                <img src={'/but_partnering_off.gif'} alt="partnering" />
                <img src={'/but_partnering_on.gif'} alt="partnering" />
            </Link>
            <Link to='/resources' className="resources">
                <img src={'/but_resources_off.gif'} alt="resources" />
                <img src={'/but_resources_on.gif'} alt="resources" />
            </Link>
            <Link to='/news' className="news">
                <img src={'/but_news_off.gif'} alt="news" />
                <img src={'/but_news_on.gif'} alt="news" />
            </Link>
            <Link to="/careers" className="careers">
                <img src={'/but_careers_off.gif'} alt="careers" />
                <img src={'/but_careers_on.gif'} alt="careers" />
            </Link>
            <Link to='/contact' className="contact">
                <img src={'/but_contact_off.gif'} alt="contact" />
                <img src={'/but_contact_on.gif'} alt="contact" />
            </Link>

        </div>
    )

}


export default Navigation;